export const environment = {
    production: false,
    readonly: true
}

export const apis = {
    baseUrl: 'https://synergymarchive-api.unitedcheerleading.com/api',
    googleApiKey: 'AIzaSyCD2nTygZ9zur-WDtgaW5MTK7w4-05k_Oo',
    // googleApiKey: 'AIzaSyBH2NFiXlM-Vt6Z08lo-26AvfyrOV9xvBM',
    googleCaptchaSiteKey: '',
    channel: 'live-send-notification',
    archiveURL: 'https://synergymarchive.codegenio.com',
    socketURL: 'https://synergym-notification-api.codegenio.com'
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}
export const paymentUrl = 'https://synergym-api.unitedcheerleading.com/api/student/pay-now'

export const appURL = 'https://synergym.codegenio.com'
